
.menu {
position: relative;
background-color: transparent;
border: none;
top: -30px;
cursor: pointer;
display: flex;
flex-direction: row;
height: 40px;
width: 40px;

}
.line {
fill: none;
stroke: white;
stroke-width: 6;

transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* .menu:hover .line{
    stroke: var(--text-color);
} */

.line1 {
stroke-dasharray: 60 207;
stroke-width: 6;
}
.line2 {
stroke-dasharray: 60 60;
stroke-width: 6;
}
.line3 {
stroke-dasharray: 60 207;
stroke-width: 6;
}
.opened .line1 {
stroke-dasharray: 90 207;
stroke-dashoffset: -134;
stroke-width: 6;
}
.opened .line2 {
stroke-dasharray: 1 60;
stroke-dashoffset: -30;
stroke-width: 6;
}
.opened .line3 {
stroke-dasharray: 90 207;
stroke-dashoffset: -134;
stroke-width: 6;
}
