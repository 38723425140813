@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'quicksand';
}


.App{
    width: 100vw;
    height: 100%;
    position: relative;
    background-color: rgb(10,10,10);
    overflow-x: hidden;
}


code{
  font-family: 'quick-sand';
}

/* width */
*::-webkit-scrollbar {
  display: none;
}

@keyframes animateDown {
  0%{
    transform: rotateY(180deg);
  }

  100%{
    transform: rotateY(0deg);
  }
}

@keyframes hand {
  0%{
    transform: rotateZ(-10deg);
  }

  25%{
    transform: rotateZ(10deg);
  }

  75%{
    transform: rotateZ(-10deg);
  }

  100%{
    transform: rotateZ(0deg);
  }
}

/* :root {
  --text-color: rgb(32, 177, 169);
  --acsent: rgb(10,10,10);
  --name-color: rgb(200, 200, 200);
  --text-hover: rgb(83, 235, 227);
} */

/* --text-color: rgb(32, 177, 169); */

:root {
  --text-color: rgb(245, 177, 69);
  --acsent: rgb(10,10,10);
  --name-color: rgb(200, 200, 200);
  --text-hover: rgb(245, 170, 48);
  --acsent2: rgb(200,200,200);
  --bg-green: rgba(245, 170, 48, 0.2);
  --pj-back: rgba(20, 20, 20,1);
} 


/** :root{

  --text-color: #00ad8d;
  --acsent: rgb(10,10,10);
  --acsent: #01241d; 
  --name-color: rgb(200, 200, 200);
  --text-hover: #00e3b9;
  --acsent2: rgb(200,200,200);
  --bg-green: #009076;
  --pj-back: rgb(21, 21, 21);

}  */